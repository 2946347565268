import 'moment';
import 'bootstrap';
import 'bootstrap-select';

// DO NOT REMOVE, REQUIRED FOR AJAX REQUESTS
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
// END DO NOT REMOVE


$(document).ready(function () {

    $('[data-toggle="popover"]').popover();

    tinymce.init({
        selector: '.tinymce',
        height: 300,
        theme: 'modern',
        font_formats: 'Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats',
        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
        toolbar1: 'undo redo | insert | styleselect | fontselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
        toolbar2: 'print preview media | forecolor backcolor  | codesample help',
        relative_urls: false,
        remove_script_host: false,
        // document_base_url : "http://ae-dev-1.staxoweb.co.uk/",
        file_browser_callback: function (field_name, url, type, win) {
            if (type == 'image') $('#formUpload input').click();
        },
        plugins: [
            'advlist autolink lists link image charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen',
            'insertdatetime media nonbreaking save table contextmenu directionality',
            'emoticons template paste textcolor colorpicker textpattern imagetools codesample  toc help'
        ]
    });

    tinymce.init({
        selector: '.tinymce-simple',
        height: 300,
        theme: 'modern',
        font_formats: 'Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats',
        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
        toolbar: 'undo redo | styleselect | fontselect fontsizeselect | forecolor | bold italic underline | bullist numlist',
        relative_urls: false,
        remove_script_host: false,
        plugins: [
            'autolink lists link hr pagebreak',
            'insertdatetime nonbreaking table',
            'paste textcolor'
        ]
    });

    $('#datepicker').datetimepicker({
        format: 'DD/MM/YYYY',
        viewMode: 'years'
    });
    $('.confirm').on('click', function (e) {
        let resp = confirm("Are you sure you want to delete?");
        if (!resp) {
            e.preventDefault();
        }
    });

    $('body#register #secondary-parent-option').bind('change', function (e) {
        if ($('#secondary-parent-option').val() == "Yes") {
            $('div.secondary-parent-group > .form-group').show();
        } else {
            $('div.secondary-parent-group > .form-group').hide();
        }
    });

    $('body#child-add, body#child-edit').find('.update-child-name').keyup(function () {
        var form = $(this).closest('form');
        var name = $.trim(form.find('#forename').val() + ' ' + form.find('#surname').val());

        $('.text-underline').text(name || 'this child');
    });

    /* ADM: Waiting List functionality */
    $('body#admin-courses-groups-waiting_list .view-notes').click(function (e) {
        e.preventDefault();

        var modal = $('body#admin-courses-groups-waiting_list #WLViewNotesModal');

        modal.data('url', $(this).data('url'));

        $.ajax({
            type: 'GET',
            dataType: 'json',
            url: $(this).data('url')
        }).done(function (data) {

            modal.find('#content').val(data.content);

        }).fail(function (data) {

        }).always(function (data) {
            $('#WLViewNotesModal').modal();
        });

    });

    $('body#admin-courses-groups-waiting_list .modal .modal-footer button.save').click(function () {
        var modal = $(this).closest('.modal');

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: modal.data('url'),
            data: {
                content: modal.find('#content').val(),
            }
        }).done(function (data) {

        }).fail(function (data) {

        }).always(function (data) {

            if (data.status == 'success') {
                modal.modal('hide');
            } else if (data.status == 'error') {
                alert('Something didn\'t work here, please try again. If the problem still persists, please check that you are the only one moving children about. If the problem still persists, please contact Staxoweb.');
            }

        });
    });

    $('body#admin-courses-groups-waiting_list .insert-manual-no-children').click(function (e) {
        $('#WLInsertManualEntryModal').data('url', $(this).data('url'));
        $('#WLInsertManualEntryModal').attr('data-url', $(this).data('url'));


        $('#WLInsertManualEntryModal').modal();
    });

    $('body#admin-courses-groups-waiting_list .insert-manual-before').click(function (e) {
        e.preventDefault();

        var curr = $(this).closest('tr');

        var data = {
            insert_type: 'before',
            selected_wlid: curr.find('.wlid').html(),
            selected_child_id: curr.find('.child_id').html(),
            selected_child_name: curr.find('.child_name').html(),
            selected_child_position: curr.find('.child_position').html(),
            selected_child_requested_date: curr.find('.child_requested_date').html(),
        };

        insertMEWLModal(data);

        $('#WLInsertManualEntryModal').modal();
    });

    $('body#admin-courses-groups-waiting_list .insert-manual-after').click(function (e) {
        e.preventDefault();

        var curr = $(this).closest('tr');

        var data = {
            insert_type: 'after',
            selected_wlid: curr.find('.wlid').html(),
            selected_child_id: curr.find('.child_id').html(),
            selected_child_name: curr.find('.child_name').html(),
            selected_child_position: curr.find('.child_position').html(),
            selected_child_requested_date: curr.find('.child_requested_date').html(),
        };

        insertMEWLModal(data);

        $('#WLInsertManualEntryModal').modal();
    });

    $('body#admin-courses-groups-waiting_list #WLInsertManualEntryModal').on('show.bs.modal', function () {
        var insert_type = $('body#admin-courses-groups-waiting_list .modal-body select#insert_type').val();
        $('body#admin-courses-groups-waiting_list .modal-body span.insert_type_observer').html(insert_type);

    }).on('hidden.bs.modal', function () {
        wipeMEWLModal();
    });

    $('body#admin-courses-groups-waiting_list #WLViewNotesModal').on('hidden.bs.modal', function () {
        $(this).data('url', '#');
        $(this).find('#content').val('');
    });

    $('body#admin-courses-groups-waiting_list .modal-body input#booking_id').keyup(function () {
        // Restrict numbers only
        if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
            this.value = this.value.replace(/[^0-9\.]/g, '');
        }

        var child_id_field = $('body#admin-courses-groups-waiting_list .modal-body input#child_id');

        // If is not empty, disable child_id field
        if ($(this).val()) {
            $(child_id_field).attr('disabled', 'disabled').val('');
        } else {
            $(child_id_field).removeAttr('disabled').val('');
        }
    });

    $('body#admin-courses-groups-waiting_list .modal-body input#child_id').keyup(function () {
        // Restrict numbers only
        if (this.value != this.value.replace(/[^0-9\.]/g, '')) {
            this.value = this.value.replace(/[^0-9\.]/g, '');
        }

        var booking_id_field = $('body#admin-courses-groups-waiting_list .modal-body input#booking_id');

        // If is not empty, disable child_id field
        if ($(this).val()) {
            $(booking_id_field).attr('disabled', 'disabled').val('');
        } else {
            $(booking_id_field).removeAttr('disabled').val('');
        }
    });

    $('body#admin-courses-groups-waiting_list .modal .modal-footer button.insert').click(function () {
        var modal = $(this).closest('.modal');

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: modal.data('url'),
            data: {
                booking_id: modal.find('#booking_id').val(),
                child_id: modal.find('#child_id').val(),
                insert_type: modal.find('#insert_type').val(),
                selected_wlid: modal.find('#selected_wlid').val(),
                selected_child_id: modal.find('#selected_child_id').val(),
                selected_child_name: modal.find('#selected_child_name').val(),
                selected_child_position: modal.find('#selected_child_position').val(),
                selected_child_requested_date: modal.find('#selected_child_requested_date').val()
            }
        }).done(function (data) {

        }).fail(function (data) {

        }).always(function (data) {

            if (data.status == 'success') {
                location.reload();
            } else if (data.status == 'error') {
                alert('Something didn\'t work here, please try again. If the problem still persists, please check that you are the only one moving children about. If the problem still persists, please contact Staxoweb.');
            }

        });
    });

    $('body#admin-courses-groups-waiting_list .modal-body select#insert_type').change(function () {
        $('body#admin-courses-groups-waiting_list .modal-body span.insert_type_observer').html($(this).val());
    });

    $('body#admin-courses-groups-waiting_list .move-up').click(function (e) {
        e.preventDefault();

        var curr = $(this).closest('tr');
        var prev = curr.prev('tr');
        var data = prev.data('wlid');

        var curr_order = $(curr).find('.order').html();
        var prev_order = $(prev).find('.order').html();

        if (data !== undefined && data !== null) {
            $.post($('.reference-table').data('url'), {

                wl_first_id: curr.data('wlid'),
                wl_second_id: prev.data('wlid')

            }).done(function (data) {

                $(curr).find('.order').html(prev_order);
                $(prev).find('.order').html(curr_order);

                $(prev).before($(curr));

                addInfoClassToWLRow(curr);
                addInfoClassToWLRow(prev);

            }).fail(function (data) {

                alert('Something didn\'t work here, please try again. If the problem still persists, please check that you are the only one moving children about. If the problem still persists, please contact Staxoweb.');

            }).always(function (data) {

                console.log(data);

            });
        } else {
            alert('I\'m sorry, Dave. I\'m afraid I can\'t do that.');
        }

        $(this).closest('.btn-group').removeClass('open');
    });

    $('body#admin-courses-groups-waiting_list .move-down').click(function (e) {
        e.preventDefault();

        var curr = $(this).closest('tr');
        var next = curr.next('tr');
        var data = next.data('wlid');

        var curr_order = $(curr).find('.order').html();
        var next_order = $(next).find('.order').html();

        if (data !== undefined && data !== null) {
            $.post($('.reference-table').data('url'), {

                wl_first_id: curr.data('wlid'),
                wl_second_id: next.data('wlid')

            }).done(function (data) {

                $(curr).find('.order').html(next_order);
                $(next).find('.order').html(curr_order);

                $(next).after($(curr));

                addInfoClassToWLRow(curr);
                addInfoClassToWLRow(next);

            }).fail(function (data) {

                alert('Something didn\'t work here, please try again. If the problem still persists, please check that you are the only one moving children about. If the problem still persists, please contact Staxoweb.');

            }).always(function (data) {

                console.log(data);

            });
        } else {
            alert('I\'m sorry, Dave. I\'m afraid I can\'t do that.');
        }

        $(this).closest('.btn-group').removeClass('open');
    });
    /* END ADM: Waiting List functionality */

    $(".group-item").hover(
        function () {
            // $( this ).find('.group-content .btn').css('background-color', '#007DC3');
            // $( this ).css('border-color', '#007DC3');
            // $( this ).find('hr').css('border-color', '#007DC3');

        },
        function () {
            // $( this ).css("border-color", "silver");
            // $( this ).find('.group-content .btn').css('background-color', '#ee1b2a');
            // $( this ).find('hr').css('border-color', '#ee1b2a');

        });

    $(".course-item").hover(
        function () {
            // $( this ).find('.course-title').css('background-color', '#007DC3');
            // $( this ).find('.btn-ae-red').css('background-color', '#007DC3');
            // $( this ).css('border-color', '#007DC3');
        },
        function () {
            // $( this ).find('.course-title').css('background-color', '#ee1b2a');
            // $( this ).find('.btn-ae-red').css('background-color', '#ee1b2a');
            // $( this ).css('border-color', 'silver');

        });

    $(".panel-default").hover(
        function () {
            $(this).find('.panel-heading').addClass('hover-active-blue');
        },
        function () {
            $(this).find('.panel-heading').removeClass('hover-active-blue');
        }
    );

    $('#children .child').hover(
        function () {
            // $(this).find('.line').css('border-color', '#007DC3');
            // $(this).find('.inner-wrapper').css('border-color', '#007DC3');
        },
        function () {
            // $(this).find('.line').css('border-color', '#ee1b2a');
            // $(this).find('.inner-wrapper').css('border-color', 'silver');
        }
    );

    $(".btn-ae-white").hover(
        function () {
            $(this).css('border-color', '#007DC3');
            $(this).find('a').css('color', '#007DC3')
        }
        ,
        function () {
            $(this).css('border-color', '#ee1b2a');
            $(this).find('a').css('color', '#ee1b2a')
        });

    $(".booking-item").hover(
        function () {
            $(this).find('.payment-info-container').css('border-color', '#007DC3');
            $(this).find('hr').css('border-color', '#007DC3');
            $(this).find('.border-right').css('border-color', '#007DC3');
            $(this).find('img').css('border-color', '#007DC3');
        },
        function () {
            $(this).find('.payment-info-container').css('border-color', 'silver');
            $(this).find('hr').css('border-color', '#ee1b2a');
            $(this).find('.border-right').css('border-color', '#ee1b2a');
            $(this).find('img').css('border-color', 'silver');
        });

    $('.icon-container').hover(function () {
            //$(this).css('blue');
            $(this).siblings(".overlay").addClass("blue");
        },
        function () {
            $(this).siblings(".overlay").removeClass("blue");
        });

    $(".payment-information").click(function () {
        $(this).closest('.payment-info-container').find(".pay-info").slideToggle();
        $(this).closest('.payment-info-container').find('.payment-information').toggleClass('hide-content');
    });

    $(".mobile-menu-button").click(function () {
        $(".header-menu").slideToggle();
        $(this).toggleClass('glyphicon-plus glyphicon-menu-hamburger');
    });

    var selectIds = $('#collapse1,#collapse2,#collapse3,#collapse4,#collapse5,#collapse6, #collapse7, #collapse8, #collapse9, #collapse10, #collapse11, #collapse12, #collapse13, #collapse14, #collapse15, #collapse16');
    $(function ($) {
        selectIds.on('show.bs.collapse hidden.bs.collapse', function () {
            $(this).prev().find('.glyphicon').toggleClass('glyphicon-plus glyphicon-minus');
        })
    });

    $('#admin-course-groups-index .dropdown-menu.group-locked form > a').click(function (e) {
        e.preventDefault();

        $(this).closest('form').submit();
    });

    // $("select.multiselect").multiselect();
    //
    //
    // $('select.multiselect').select2();


    $("select.chosen-select").chosen({
        scroll_to_highlighted: false
    });

});

/* Burger button */
$('body').bind("touchstart", function (e) {
    var container = $('.header-menu-mobile-show');

    if (!container.is(e.target) // if the target of the click isn't the container...
        && container.has(e.target).length === 0) // ... nor a descendant of the container
    {
        container.removeClass('header-menu-mobile-show');
        $('.header-separator').show();
    }
});

// For registration / my profile area
function showHideSecondaryParentFields() {
    if ($('#has_secondary_guardian').val() == "yes") {
        $('div.secondary-parent-group > .orm-group').show();
    } else {
        $('div.secondary-parent-group > .orm-group').hide();
        ///
    }
}

// On change, hide or show fields
$('body#register-new #has_secondary_guardian, body#my-profile-edit #has_secondary_guardian').bind('change', function (e) {
    showHideSecondaryParentFields();
});

// On load, hide or show fields
showHideSecondaryParentFields();

/* My Profile area */
$('h2.secondary-parent').click(function () {
    $('#secondary-parent-info').toggle();
});

/* ADM: Waiting List functionality */

/* Add info class to wl row on swap */
function addInfoClassToWLRow(row) {
    $(row).addClass('info').delay(2000).queue(function () {
        $(this).removeClass('info').dequeue();
    });
}

function insertMEWLModal(data) {
    var modal = $('body#admin-courses-groups-waiting_list .modal-body');

    $.each(data, function (key, value) {
        $(modal).find('#' + key).val(value);
    });
}

function wipeMEWLModal() {
    var modal = $('body#admin-courses-groups-waiting_list .modal-body');

    var inputs = [
        'booking_id',
        'child_id',
        'selected_wlid',
        'selected_child_id',
        'selected_child_name',
        'selected_child_position',
        'selected_child_requested_date'
    ];

    var enable_inputs = [
        'booking_id',
        'child_id'
    ];

    $.each(inputs, function (index, value) {
        $(modal).find('#' + value).val('');
    });

    $.each(enable_inputs, function (index, value) {
        $(modal).find('#' + value).removeAttr('disabled');
    });
}

/* END ADM: Waiting List functionality */

// medical information enable disable thing
function checklistEnable() {
    jQuery('.medi-info-checklist').on('change', 'input[type=checkbox]', function () {
            if (this.checked) {
                jQuery(this).closest('.row').find('.required-field').removeClass('hidden');
                jQuery(this).closest('.row').find("input[type='text']").attr("readonly", false).val('');
            } else {
                jQuery(this).closest('.row').find('.required-field').addClass('hidden');
                jQuery(this).closest('.row').find("input[type='text']").attr("readonly", true).val('');
            }
        }
    );
}

checklistEnable();

$('#selectTeacher').on('change', function (e) {
    if ($('select[name=child]')[0].selectedIndex === 0) {

    } else {
        $(this).closest('form').submit();
    }
    ;
});
//disable autocomplete
$('#date').attr("autocomplete", "off");
$('#datepickerBackendFormat').datetimepicker({
    format: 'DD/MM/YYYY',
});

$(function () {
    $('[data-toggle="tooltip"]').tooltip();
});